import styled from "styled-components";
import {
  BP,
  Rem,
  Ratio,
} from "../../commons/Theme";

export const StyledCarousel = styled.div``;

export const StyledCarouselSlider = styled.div`
  margin-bottom: ${Rem(20)};
`;

export const StyledCarouselSlide = styled.div`
  ${Ratio(375, 400)};
  background-image: url('${(props) => props.bg}');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  
  @media(${BP.ipad}) {
    ${Ratio(693, 354)};
  }
`;

export const StyledCarouselThumbs = styled.div`
  padding: 0 ${Rem(20)};

  @media (${BP.ipad}) {
    padding: 0;
  }
`;

export const StyledCarouselThumb = styled.div`
  ${Ratio(693, 354)};
  background-image: url('${(props) => props.bg}');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: ${Rem(124)};
`;
